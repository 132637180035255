<template>
  <div id="app" :class="lang">
    <agency-login v-if="isAgencyLoginState"/>
    <ContentLoading v-if="isAgency && !isFetchedAllData && !isAgencyLoginState && !isProcessingBooking" type="app" />
    <Container v-if="isFetchedAllData && isActive && isAgency && !isLanding && !isLandingChain && !isAgencyLoginState " />
    <NoRegisterAgency v-if="!isAgency && !isLanding && !isLandingChain" />
    <LandingPage v-if="(isLanding || isLandingChain) && landingInfo" />

    <b-modal
      id="processingBooking"
      centered
      size="lg"
      :dir="lang === 'he' ? 'rtl' : 'ltr'"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :hide-footer="true"
    >
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline" :dir="lang==='he' ? 'rtl' : 'ltr'">
          <h3>{{ $t('booking.waiting') }}</h3>
        </div>
      </template>
      <div class="d-flex">
        <h5>{{ $t('booking.waiting-populating') }}</h5>
        <img src="/assets/img/waiting.gif" />
      </div>
    </b-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { loadProgressBar } from 'axios-progress-bar';
import 'axios-progress-bar/dist/nprogress.css';
import { BModal } from 'bootstrap-vue';
import googleAnalytics from '@/mixins/googleAnalytics';

export default {
  name: 'App',
  components: {
    BModal,
    Container: () => import('@/components/Container'),
    NoRegisterAgency: () => import('@/components/atoms/NoRegisterAgency'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
    LandingPage: () => import('@/components/landing/LandingPage'),
    AgencyLogin: () => import('@/components/agencyLogin/login'),
  },
  mixins: [googleAnalytics],
  metaInfo() {
    let title = '';
    const metaInfo = [];
    let slugInfo = null;
    const { name, meta, params: { slug } } = this.$route;
    const { destination, chain, dealType } = this.urlParams;
    switch (name) {
      case 'home':
        if (destination !== undefined) {
          title = this.$t('meta.home.title', { destinationName: this.destinationName });
          metaInfo.push({ name: 'description', content: this.$t('meta.home.description', { destinationName: this.destinationName }) });
        } else if (chain !== undefined) {
          title = this.$t('meta.chain.title', { chain });
          metaInfo.push({ name: 'description', content: this.$t('meta.home.description', { chain }) });
        } else if (dealType !== undefined) {
          title = this.$t('meta.dealType.title');
          // metaInfo.push({ name: 'description', content: this.$t('meta.dealType.description', { destinationName: this.destinationName }) });
        } else {
          title = this.$t('meta.else.title');
          metaInfo.push({ name: 'description', content: this.$t('meta.else.description') });
        }
        break;
      case 'product-page':
        title = this.$t('meta.product-page.title', { hotelName: this.hotelName, cityName: this.cityName });
        metaInfo.push({ name: 'description', content: this.$t('meta.product-page.description', { hotelName: this.hotelName, cityName: this.cityName }) });
        break;
      case 'booking':
        title = this.$t('meta.booking.title', { hotelName: this.hotelName, cityName: this.cityName });
        metaInfo.push({ name: 'description', content: this.$t('meta.booking.description', { hotelName: this.hotelName, cityName: this.cityName }) });
        break;
      case 'search-result':
        title = this.$t('meta.search-result.title', { cityName: this.cityName });
        metaInfo.push({ name: 'description', content: this.$t('meta.search-result.description', { cityName: this.cityName }) });
        break;
      case 'booking-hotel':
        title = this.$t('meta.booking-hotel.title');
        metaInfo.push({ name: 'description', content: this.$t('meta.booking-hotel.description') });
        break;
      // case 'hotels-page':
      //   title = this.$t('meta.hotels-page.title', { cityName: this.cityName });
      //   metaInfo.push({ name: 'description', content: this.$t('meta.hotels-page.description', { cityName: this.cityName }) });
      //   break;
      case 'verifying-card': case 'card-verified':
        title = this.$t('meta.verifying-card.title');
        metaInfo.push({ name: 'description', content: this.$t('meta.verifying-card.description') });
        break;
      case 'slug-info':
        if (this.footerPagesList) {
          // eslint-disable-next-line no-unused-vars
          Object.entries(this.footerPagesList).forEach(([key, slugItem]) => {
            if (slugItem.slug === slug) slugInfo = slugItem;
          });
          ({ title } = slugInfo.meta.title);
          metaInfo.push({ name: 'description', content: slugInfo.meta.description });
          metaInfo.push({ name: 'keywords', content: slugInfo.meta.keyword });
        }
        break;
      default:
        title = meta.title ? `${this.$t('meta.default.title')} - ${meta.title}` : this.$t('meta.default.title');
        // metaInfo.push({ name: 'description', content: this.$t('meta.default.description') });
    }
    return {
      title,
      meta: metaInfo,
    };
  },
  data() {
    return {
      destinationImageList: [],
      isAgency: true,
      isFetchedAllData: false,
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      metaInfo: 'GET_META_INFO',
      whiteLabel: 'GET_WHITE_LABEL',
      moreHotelInfo: 'GET_ONE_HOTEL_INFO',
      destinationList: 'GET_CAMINGO_DESTINATION',
      isLanding: 'GET_IS_LANDING_PAGE',
      isLandingChain: 'GET_IS_LANDING_PAGE_CHAIN',
      landingInfo: 'GET_LANDING_INFO',
      footerPagesList: 'GET_FOOTER_PAGES_LIST',
      isAgencyAgentLogin: 'IS_AGENCY_AUTHORIZED',
      isAgencyLoginState: 'GET_AGENCY_LOGIN_STATE',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      privateMessage: 'GET_PRIVATE_MESSAGE',
      sharedMessage: 'GET_SHARED_MESSAGE',
      errorBody: 'GET_ERROR_RESPONSE',
      isProcessingBooking: 'GET_PROCESSING_BOOKING_STATE',
    }),
    urlParams() {
      let match;
      const pl = /\+/g,
        search = /([^&=]+)=?([^&]*)/g,
        decode = (s) => decodeURIComponent(s.replace(pl, ' ')),
        query = window.location.search.substring(1);
      const urlParams = {};
      // eslint-disable-next-line no-cond-assign
      while ((match = search.exec(query))) urlParams[decode(match[1])] = decode(match[2]);

      return urlParams;
    },
    isActive() {
      return this.whiteLabel?.active;
    },
    hotelName() {
      const { moreHotelInfo } = this;
      if (!moreHotelInfo) return '';
      return moreHotelInfo?.hotelConversion?.nameTranslations[this.lang] || '';
    },
    cityName() {
      const { moreHotelInfo, destinationList } = this;
      // if (!moreHotelInfo) return '';
      if (!destinationList) return '';
      const mainCityCode = moreHotelInfo?.hotelConversion?.mainCityCode || this.$route.query.city || '';
      return (mainCityCode !== '') ? destinationList.find((item) => (item.code === mainCityCode))?.nameTranslations[this.lang] : '';
    },
    destinationName() {
      const { destinationList } = this;
      const { destination } = this.urlParams;
      if (!destinationList) return '';
      const dest = destinationList.find((item) => (item.code === destination));
      return dest ? dest.nameTranslations[this.lang] : '';
    },
  },
  watch: {
    isAgencyLoginState() {
      if (this.isAgencyAgentLogin) {
        window.location.reload();
      }
    },
    isAgencyAgentLogin() {
      if (this.isAgencyAgentLogin) {
        this.$store.dispatch('SOCKET_PRIVATE_CONNECT', this);
        this.$store.dispatch('SOCKET_SHARED_CONNECT', this);
      } else {
        this.$store.dispatch('SOCKET_DIS_CONNECT', this);
      }
    },
    privateMessage() {
      this.showAdminMessageForAgent(this.privateMessage, 'private');
    },
    sharedMessage() {
      this.showAdminMessageForAgent(this.sharedMessage, 'shared');
    },
    errorBody() {
      if (this.errorBody !== null) {
        this.showAdminMessageForAgent(this.errorBody?.statusText || '', `Error Code: ${this.errorBody?.status || ''}`);
      }
    },
    isProcessingBooking() {
      if (this.isProcessingBooking) {
        this.$bvModal.show('processingBooking');
      } else {
        this.$bvModal.hide('processingBooking');
      }
    },
  },
  async created() {
    // const gtagID = 'UA-66183279-1';
    // const gtagScript = document.createElement('script');
    // gtagScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${gtagID}`);
    // document.head.appendChild(gtagScript);
    // window.dataLayer = window.dataLayer || [];
    // this.gtag('js', new Date());
    // this.gtag('config', gtagID);

    if (window.location.pathname === '/') {
      this.$store.dispatch('STORE_IS_LANDING_STATE', this.urlParams);
    }
    window.addEventListener('resize', this.resize);
    if (window.location.href.indexOf('google') > -1) {
      this.$store.dispatch('SET_GOOGLE_STATE');
    }
    let { host } = window.location;
    // this.$store.commit('SET_STAGING_STATE', host.includes('vercel') || host.includes('localhost'));
    if (host.includes('new') || host.includes('saflycptcomiprodwsteur01')) {
      this.$store.commit('SET_AZURE_STATE', true);
    }

    if (host.includes('localhost') || host.includes('vercel')) host = 'flyingisr.com';
    // if (host.includes('localhost') || host.includes('vercel')) host = 'agent.flyingisr.com';
    // if (host.includes('localhost')) host = 'new.flyingisr.com';

    const response = await this.$store.dispatch('FETCH_WHITE_LABEL', host);
    const { data } = response;
    await this.getSetSomeParameter();
    if (window.location.pathname === '/') {
      await this.$store.dispatch('STORE_IS_LANDING_STATE', this.urlParams);
    }
    const beforeLogin = window.sessionStorage.getItem('agencyLogin') === 'true';

    if (this.isOdysseySite || beforeLogin) await this.$store.dispatch('VERIFY_AGENCY_LOGIN_STATE');
    if (this.isOdysseySite && !this.isAgencyAgentLogin) return;

    if (data?.status !== 404) {
      await this.$store.dispatch('FETCH_HOTEL_CHAIN');
      await this.$store.dispatch('FETCH_HOTEL_CATEGORY');
      await this.$store.dispatch('FETCH_CAMINGO_DESTINATION');
      await this.$store.dispatch('FETCH_STATIC_PAGE_SUBTYPE_LIST');
      this.$store.dispatch('FETCH_FOOTER_PAGES_LIST');
      await this.$store.dispatch('STORE_LANDING_URL', this.urlParams);
    } if (data?.status === 404) {
      this.isAgency = false;
    }
    this.isFetchedAllData = true;
  },
  mounted() {
    this.resize();
    window.scrollTo(0, 0);
    loadProgressBar({ showSpinner: false });
  },
  destroyed() {
    window.removeEventListener('resize', this.resize);
  },
  methods: {
    resize() {
      let device = 'desktop';
      const { offsetWidth } = document.getElementById('app');
      if (offsetWidth < 479) {
        device = 'mobile';
      } else {
        device = 'desktop';
      }
      this.$store.dispatch('UPDATE_DEVICE', device);
    },
    async getSetSomeParameter() {
      const { dealType, marketerId, subMarketerId } = this.urlParams;
      // get marketerId
      // if (dealType) await this.$store.dispatch('FETCH_MARKETER_ID', dealType);
      // set MargeterId
      if (marketerId && marketerId !== '') this.$store.commit('SET_MARKETER_ID', marketerId);

      // set MarketerAgency
      // const marketerAgency = dealType;
      // if (marketerAgency) this.$store.commit('SET_AGENCY_FROM_MARKETER_ID', marketerAgency);
      // set SubAgency
      if (subMarketerId && subMarketerId !== '') this.$store.commit('SET_SUB_AGENCY', subMarketerId);
      // if (marketerId) await this.$store.dispatch('FETCH_AGENCY_FROM_MARKETER_ID');
      if (marketerId || dealType) await this.$store.dispatch('FETCH_AGENCY_CONTENT_FROM_MARKETER_ID');
    },
    showAdminMessageForAgent(messageBody, type) {
      const h = this.$createElement;
      const messageContent = h('div', {
        domProps: {
          innerHTML: messageBody,
        },
      });
      const id = `${type}-${this.count += 1}`;
      const closeButton = h(
        'b-button',
        {
          props: { variant: 'primary', size: 'sm' },
          on: { click: () => this.$bvToast.hide(id) },
        },
        'Close',
      );
      this.$bvToast.toast([messageContent, closeButton], {
        id,
        title: `${type} message`,
        variant: 'danger',
        solid: true,
        noAutoHide: true,
        toaster: 'b-toaster-top-center',
        bodyClass: ['text-right', 'message-content'],
        noCloseButton: true,
      });
    },
  },
};
</script>

<style lang="sass">
@import '~bootstrap/scss/bootstrap.scss'
@import '~bootstrap-vue/src/index.scss'
.agile
  &__nav-button
    background: #fff
    border: 1px solid #4285f4
    color: #4285f4
    cursor: pointer
    font-size: 20px
    height: 40px
    position: absolute
    top: calc(50% - 40px)
    transition-duration: .3s
    width: 40px
    border-radius: 50%
    transition: 0.5s all

    &:hover
      border: none
      color: black
      box-shadow: gray -1px 1px 10px
      opacity: 1

    &--prev
      left: 0

    &--next
      right: 0

  &__dots
    bottom: 10px
    left: 50%
    position: absolute
    transform: translateX(-50%)

  &__dot
    margin: 0 10px

    button
      background-color: transparent
      border: 1px solid #fff
      border-radius: 50%
      cursor: pointer
      display: block
      height: 10px
      font-size: 0
      line-height: 0
      margin: 0
      padding: 0
      transition-duration: .3s
      width: 10px

    &--current,
    &:hover
      button
        background-color: #fff
</style>

<style>
#app {
  font-family: "ploni", "Poppins", sans-serif;
  height: 100%;
}
html {
  scroll-behavior: smooth;
}
 #nprogress .bar {
  background: rgb(0, 255, 149) !important;
  height: 5px;
}
#nprogress .peg {
  box-shadow: 0 0 10px rgb(0, 255, 149), 0 0 5px rgb(0, 255, 149) !important;
}
#nprogress .spinner-icon {
  border-top-color: rgb(0, 255, 149) !important;
  border-left-color: rgb(0, 255, 149) !important;
}
.st-content{
  scroll-behavior: smooth;
}
#processingBooking + .modal-backdrop {
  opacity: 1;
  background-color: white;
}
#processingBooking .modal-content {
  box-shadow: #cac8c8 1px 1px 20px;
}
#processingBooking.ltr {
  direction: ltr;
}
#processingBooking.rtl {
  direction: rtl;
}
#processingBooking .modal-body h5 {
  padding: 35px 20px;
  font-size: 30px;
}
#processingBooking img {
  width: 200px;
}
#processingBooking h3 {
  font-size: 22px;
  font-weight: 600;
  color: #313131;
  text-align: center;
}
#processingBooking.ltr {
  direction: ltr;
}
#processingBooking.rtl {
  direction: rtl;
}
#processingBooking .modal-body h5 {
  padding: 35px 20px;
  font-size: 30px;
}
#processingBooking img {
  width: 200px;
}
#processingBooking h3 {
  font-size: 22px;
  font-weight: 600;
  color: #313131;
  text-align: center;
}
#b-toaster-top-center {
  top: calc(50vh - 70px);
}
#processingBooking.modal .modal-dialog {
  display: flex;
  font-family: 'ploni', 'Poppins', sans-serif;
  font-weight: 500;
}
#processingBooking.modal:before {
  display: none;
}
#processingBooking.modal .modal-body {
  display: flex;
  padding: 0px;
  margin: 0px;
}
@media (max-width:479px){
  #processingBooking img {
    width: 100px;
    height: 100px;
  }
  #processingBooking .modal-body h5 {
    font-size: 20px;
  }
}
</style>
