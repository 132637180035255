import Vue from 'vue';
import Vuex from 'vuex';

import {
  get,
  post,
  postWithText,
} from '@/utils/api';
import { toBase64 } from '@/utils/base64Util';
import agencyUsers from './modules/agencyUsers';
import memberUsers from './modules/memberUsers';
import socketStore from './modules/socketStore';
import pendingStore from './modules/pendingStore';
import cancelBookStore from './modules/cancelBookStore';

const { VUE_APP_GOOGLE_PHONE_NUMBER, VUE_APP_FACEBOOK_PHONE_NUMBER, VUE_APP_DESKTOP_PHONE_NUMBER, VUE_APP_MOBILE_PHONE_NUMBER } = process.env;
const { VUE_APP_LANDING_PAGE_KEY, VUE_APP_LANDING_PAGE_CHAIN_KEY, VUE_APP_LANDING_UTM_SOURCE_KEY, VUE_APP_VACATION_PHONE_NUMBER } = process.env;
const { VUE_APP_SUPPLIER_SEARCH_LIST } = process.env;

const languageList = {
  en: 'ENG',
  he: 'HEB',
  ru: 'RUS',
  ar: 'ARA',
};

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    agencyUsers,
    memberUsers,
    socketStore,
    pendingStore,
    cancelBookStore,
  },
  state: {
    isStaging: false,
    isAzure: false,
    developMode: false,
    categories: null,
    calendarData: null,
    holidayData: null,
    packages: [],
    currentCategory: null,
    isLoading: false,
    dateLoading: false,
    // product: null,
    // roomClass: [],
    // roomType: [],
    // roomList: [],
    bookingInfo: null,
    bookingDataID: '',
    dealType: [],
    dealData: null,
    language: 'en',
    device: 'desktop',
    verifyingURL: '',
    bannerImages: null,
    destinationImages: [],
    // camingoDealData: [],
    whiteLabel: {},
    footerPagesList: null,
    footerCategoryList: null,
    footerPageContent: null,
    // camingo part:
    camingoDestination: [],
    camingoHotels: [],
    searchContent: {
      dest: '',
      hotels: [],
      from: '',
      to: '',
      adult: 0,
      child: 0,
      infant: 0,
    },
    typeChannel: '',
    currentPage: 'home',
    oneHotelInfo: null,
    isGoogle: false,
    hotelChain: [],
    hotelActiveChain: [],
    hotelCategory: [],
    hotelAllInfo: [],
    messageFromServer: '',
    nationality: [],
    // Landing page part
    destDataForLp: null,
    isLandingPage: false,
    isLandingPageChain: false,
    landingInfo: null,
    landingDealData: [],
    landingDealChainData: [],
    errorResponse: null,
    isProcessingBooking: false,
    vipReservationEmailResult: null,

    openNewWindowFooterPage: ['Airport Assistance', 'Transfers', 'sightseeing tours'],
  },
  getters: {
    GET_MODE: (state) => state.developMode,
    GET_IS_AZURE_STATE: (state) => state.isAzure,
    GET_CATEGORIES: (state) => state.categories,
    GET_CALENDAR_DATA: (state) => state.calendarData,
    GET_HOLIDAY_DATA: (state) => state.holidayData,
    GET_PACKAGES: (state) => state.packages,
    GET_CURRENT_CATEGORY: (state) => state.currentCategory,
    GET_LOADING_STATE: (state) => state.isLoading,
    GET_DATE_LOADING_STATE: (state) => state.dateLoading,
    GET_BOOKING_INFO: (state) => state.bookingINfo,
    GET_BOOKING_DATA_ID: (state) => state.bookingDataID,
    GET_DEAL_TYPE: (state) => state.dealType,
    GET_DEAL_DATA: (state) => state.dealData,
    GET_LANGUAGE: (state) => state.language,
    GET_DEVICE: (state) => state.device,
    GET_VERIFYING_URL: (state) => state.verifyingURL,
    GET_BANNER_IMAGES: (state) => state.bannerImages,
    GET_DESTINATION_IMAGES: (state) => state.destinationImages,
    // camingo part:
    GET_WHITE_LABEL_DATA: (state) => {
      if (state.isGoogle) {
        state.whiteLabel.phoneNumber = VUE_APP_GOOGLE_PHONE_NUMBER;
      } else if (state.device === 'desktop') {
        state.whiteLabel.phoneNumber = VUE_APP_DESKTOP_PHONE_NUMBER;
      } else if (state.device === 'mobile') {
        state.whiteLabel.phoneNumber = VUE_APP_MOBILE_PHONE_NUMBER;
      }
      state.whiteLabel.vacationPhoneNumber = VUE_APP_VACATION_PHONE_NUMBER;
      return state.whiteLabel;
    },
    GET_WHITE_LABEL: (state) => state.whiteLabel,
    GET_FOOTER_PAGES_LIST: (state) => state.footerPagesList,
    GET_FOOTER_CATEGORY_LIST: (state) => state.footerCategoryList,
    GET_FOOTER_PAGE_CONTENT: (state) => state.footerPageContent,
    GET_TYPE_CHANNEL: (state) => state.typeChannel,
    GET_CAMINGO_DESTINATION: (state) => state.camingoDestination,
    GET_CAMINGO_HOTELS: (state) => state.camingoHotels,
    GET_SEARCH_CONTENT: (state) => state.searchContent,
    GET_ONE_HOTEL_INFO: (state) => state.oneHotelInfo,
    GET_HOTEL_CHAIN_TABLE: (state) => state.hotelChain,
    GET_HOTEL_ACTIVE_CHAIN: (state) => state.hotelActiveChain,
    GET_HOTEL_CATEGORY: (state) => state.hotelCategory,
    GET_HOTEL_ALL_INFO: (state) => state.hotelAllInfo,
    GET_MESSAGE_FROM_SERVER: (state) => state.messageFromServer,
    GET_NATIONALITY: (state) => state.nationality,
    // Landing page part
    GET_DEST_DATA_FOR_LP: (state) => state.destDataForLp,
    GET_IS_LANDING_PAGE: (state) => state.isLandingPage,
    GET_IS_LANDING_PAGE_CHAIN: (state) => state.isLandingPageChain,
    GET_LANDING_INFO: (state) => state.landingInfo,
    GET_LANDING_DEAL_DATA: (state) => state.landingDealData,
    GET_LANDING_DEAL_CHAIN_DATA: (state) => state.landingDealChainData,

    GET_CURRENT_PAGE: (state) => state.currentPage,
    GET_ERROR_RESPONSE: (state) => state.errorResponse,
    GET_PROCESSING_BOOKING_STATE: (state) => state.isProcessingBooking,
    GET_VIP_RESERVATION_EMAIL_RESULT: (state) => state.vipReservationEmailResult,

    GET_OPEN_NEW_WINDOW_FOOTER_PAGE: (state) => state.openNewWindowFooterPage,
  },
  mutations: {
    SET_STAGING_STATE: (state, payload) => {
      state.isStaging = payload;
    },
    SET_AZURE_STATE: (state, payload) => {
      state.isAzure = payload;
    },
    SET_CATEGORIES: (state, payload) => {
      state.categories = payload;
    },
    SET_CALENDAR_DATA: (state, payload) => {
      state.calendarData = payload;
    },
    SET_HOLIDAY_DATA: (state, payload) => {
      state.holidayData = payload;
    },
    SET_PACKAGES: (state, payload) => {
      state.packages = payload;
    },
    SET_CURRENT_CATEGORY: (state, payload) => {
      state.currentCategory = state.categories.find((item) => (item.code === payload));
    },
    SET_LOADING_STATE: (state, payload) => {
      state.isLoading = payload;
    },
    SET_DATE_LOADING_STATE: (state, payload) => {
      state.dateLoading = payload;
    },
    SET_SEARCH_ITEM_CONTENT: (state, payload) => {
      state.searchContent[payload[0]] = payload[1];
    },
    SET_DEAL_TYPE: (state, payload) => {
      state.dealType = payload;
    },
    SET_DEAL_DATA: (state, payload) => {
      state.dealData = payload;
    },
    SET_LANGUAGE: (state, payload) => {
      state.language = payload;
    },
    SET_DEVICE: (state, payload) => {
      state.device = payload;
    },
    SET_VERIFYING_URL: (state, payload) => {
      state.verifyingURL = payload;
    },
    SET_BANNER_IMAGES: (state, payload) => {
      state.bannerImages = payload;
    },
    SET_DESTINATION_IMAGES: (state, payload) => {
      state.destinationImages = payload;
    },
    SET_WHITE_LABEL: (state, payload) => {
      state.whiteLabel = payload;
    },
    SET_FOOTER_PAGES_LIST: (state, payload) => {
      state.footerPagesList = (payload === undefined || payload === '') ? null : payload;
    },
    SET_FOOTER_CATEGORY_LIST: (state, payload) => {
      state.footerCategoryList = (payload === undefined || payload === '') ? null : payload;
    },
    SET_FOOTER_PAGE_CONTENT: (state, payload) => {
      state.footerPageContent = (payload === undefined || payload === '') ? null : payload;
    },
    SET_TYPE_CHANNEL: (state, payload) => {
      state.typeChannel = payload;
    },
    /**
     *  camingo part:
     *  */
    SET_CAMINGO_DESTINATION: (state, payload) => {
      state.camingoDestination = payload;
    },
    SET_CAMINGO_HOTELS: (state, payload) => {
      state.camingoHotels = payload;
    },
    SET_ONE_HOTEL_INFO: (state, payload) => {
      state.oneHotelInfo = payload;
    },
    SET_BOOKING_INFO: (state, payload) => {
      state.bookingInfo = payload;
    },
    SET_BOOKING_DATA_ID: (state, payload) => {
      state.bookingDataID = payload;
    },
    SET_GOOGLE_STATE: (state) => {
      state.isGoogle = true;
    },
    SET_HOTEL_CHAIN_TABLE: (state, payload) => {
      state.hotelChain = payload;
    },
    SET_HOTEL_ACTIVE_CHAIN: (state, payload) => {
      state.hotelActiveChain = payload;
    },
    SET_HOTEL_CATEGORY: (state, payload) => {
      state.hotelCategory = payload;
    },
    SET_HOTEL_ALL_INFO: (state, payload) => {
      state.hotelAllInfo = payload;
    },
    SET_CURRENT_PAGE: (state, payload) => {
      state.currentPage = payload;
    },
    SET_NATIONALITY: (state, payload) => {
      state.nationality = payload;
    },

    // Landing page part
    SET_DEST_DATA_FOR_LP: (state, payload) => {
      state.destDataForLp = payload;
    },
    SET_LANDING_PAGE: (state, payload) => {
      state.isLandingPage = payload;
    },
    SET_LANDING_PAGE_CHAIN: (state, payload) => {
      state.isLandingPageChain = payload;
    },
    SET_LANDING_INFO: (state, payload) => {
      state.landingInfo = payload;
    },
    SET_LANDING_DEAL_DATA: (state, payload) => {
      state.landingDealData = payload;
    },
    SET_LANDING_DEAL_CHAIN_DATA: (state, payload) => {
      state.landingDealChainData = payload;
    },

    SET_ERROR_RESPONSE: (state, payload) => {
      state.errorResponse = payload;
    },
    SET_PROCESSING_BOOKING_STATE: (state, payload) => {
      state.isProcessingBooking = payload;
    },
    SET_VIP_RESERVATION_EMAIL_RESULT: (state, payload) => {
      state.vipReservationEmailResult = payload;
    },
  },
  actions: {
    UPDATE_DEVICE: (context, payload) => {
      context.commit('SET_DEVICE', payload);
    },
    UPDATE_LANGUAGE: (context, payload) => {
      context.commit('SET_LANGUAGE', payload);
    },
    UPDATE_CURRENT_CATEGORY: (context, payload) => {
      const {
        categoryId,
      } = payload;
      context.commit('SET_CURRENT_CATEGORY', categoryId);
    },
    FETCH_HOLIDAY_DATA: async (context, payload) => {
      const { agencyAuthorized } = context.state.agencyUsers;
      context.commit('SET_DATE_LOADING_STATE', true);
      try {
        const response = await get('BFF', `/operation/packDates/holidays?year=${payload}&year=${payload + 1}`, context);
        const holidays = [...response.data[0].holidays, ...response.data[1].holidays];

        context.commit('SET_HOLIDAY_DATA', holidays);
        context.commit('SET_DATE_LOADING_STATE', false);
      } catch (error) {
        if (agencyAuthorized && error.response.status === 401) context.commit('SET_AGENCY_LOGIN_STATE', true);
        context.commit('SET_ERROR_RESPONSE', error.response);
      }
    },
    SET_SEARCH_ITEM: async (context, payload) => {
      context.commit('SET_SEARCH_ITEM_CONTENT', payload);
    },
    GET_SUCCEED_BOOKING: async (context) => {
      context.commit('SET_PROCESSING_BOOKING_STATE', true);
      const BookingDataID = window.localStorage.getItem('BookingDataID');
      try {
        const response = await get('CAMINGO', `/operation/succeededBooking/${BookingDataID}`, context);
        context.commit('SET_PROCESSING_BOOKING_STATE', false);
        return response;
      } catch (error) {
        context.commit('SET_PROCESSING_BOOKING_STATE', false);
        context.commit('SET_ERROR_RESPONSE', error.response);
        return error.response;
      }
    },
    FETCH_DESTINATION_IMAGES: async (context) => {
      try {
        const response = await get('BFF', '/destinationEx/?from=1&to=1000', context);
        context.commit('SET_DESTINATION_IMAGES', response.data.data);
        return response.data.data;
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        return error.response;
      }
    },
    FETCH_WHITE_LABEL: async (context, payload) => {
      try {
        const response = await get('BFF', `/agencyContents/byHost/${payload}`, context);
        context.commit('SET_WHITE_LABEL', response.data);
        context.commit('SET_ODYSSEY_AGENT_CONTENT', response.data);

        return response;
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        return { data: { status: 404 } };
      }
    },
    FETCH_FOOTER_PAGES_LIST: async (context) => {
      let response = null;
      try {
        response = await get('CAMINGO', '/footerItem/pageList', context);
        context.commit('SET_FOOTER_PAGES_LIST', response.data);
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        context.commit('SET_FOOTER_PAGES_LIST', null);
      }
      return response;
    },
    FETCH_FOOTER_PAGE_CONTENT: async (context, payload) => {
      let response = null;
      try {
        response = await get('CAMINGO', `/footerItem/${payload}`, context);
        context.commit('SET_FOOTER_PAGE_CONTENT', response.data);
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        context.commit('SET_FOOTER_PAGE_CONTENT', null);
      }
      return response;
    },
    FETCH_STATIC_PAGE_SUBTYPE_LIST: async (context) => {
      let response = null;
      try {
        response = await get('CAMINGO', '/footerCategory/activeOnly', context);
        context.commit('SET_FOOTER_CATEGORY_LIST', response.data);
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        context.commit('SET_FOOTER_CATEGORY_LIST', null);
      }
      return response;
    },
    /**
     * camingo site part:
     *  */
    FETCH_CAMINGO_DESTINATION: async (context) => {
      try {
        const response = await get('CAMINGO', '/destination?from=1&to=1000', context);
        context.commit('SET_CAMINGO_DESTINATION', response.data);
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
      }
    },
    FETCH_CAMINGO_HOTELS: async (context) => {
      try {
        const response = await get('CAMINGO', '/hotelConversion?from=1&to=1000', context);
        context.commit('SET_CAMINGO_HOTELS', response.data);
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
      }
    },
    FETCH_PACKAGE_BY_SEARCH: async (context, payload) => {
      const url = '/operation/search';
      const suppliers = VUE_APP_SUPPLIER_SEARCH_LIST.split(',');
      const { hotelCode, sources } = payload;
      const { agencyAuthorized } = context.state.agencyUsers;
      const hotelCodeList = hotelCode ? hotelCode.split(',') : [];
      const sourceArray = sources ? sources.split(',').map((src) => suppliers[src]) : [];
      const responseArray = [];
      const body = { ...payload };
      const { subCalling } = body;
      let response = null;
      if (!subCalling)context.commit('SET_LOADING_STATE', true);

      if (hotelCodeList.length > 0) {
        for (let i = 0; i < hotelCodeList.length; i += 1) {
          body.hotelCode = hotelCodeList[i];
          try {
            if (sources) body.suppliers = [sourceArray[i]];
            // eslint-disable-next-line no-await-in-loop
            response = await post('CAMINGO', url, body, context);
            responseArray.push(...response.data);
          } catch (error) {
            context.dispatch('NOTIFY_ADMIN', {
              subject: `CAMINGO - The search is getting an error - ${error.response.status}`,
              body: `<h4>${JSON.stringify(body)}</h4> <p>${url}</p><p>${JSON.stringify(error.response)}</p>`,
            });
            context.commit('SET_ERROR_RESPONSE', error.response);
          }
        }
      } else {
        try {
          response = await post('CAMINGO', url, payload, context);
          responseArray.push(...response.data);
        } catch (error) {
          if (agencyAuthorized && error.response.status === 401) context.commit('SET_AGENCY_LOGIN_STATE', true);
          context.dispatch('NOTIFY_ADMIN', {
            subject: `CAMINGO - The search is getting an error - ${error.response.status}`,
            body: `<h4>${JSON.stringify(body)}</h4> <p>${url}</p><p>${JSON.stringify(error.response)}</p>`,
          });
          context.commit('SET_ERROR_RESPONSE', error.response);
        }
      }
      if (!subCalling) { context.commit('SET_PACKAGES', responseArray); }
      if (!subCalling) context.commit('SET_LOADING_STATE', false);

      return responseArray;
    },
    FETCH_DEAL_TYPE_LIST: async (context) => {
      const { agencyAuthorized } = context.state.agencyUsers;
      try {
        const response = await get('CAMINGO', '/dealCategory/onlyActive', context);
        context.commit('SET_DEAL_TYPE', response.data);
        return response;
      } catch (error) {
        if (agencyAuthorized && error.response.status === 401) context.commit('SET_AGENCY_LOGIN_STATE', true);
        context.commit('SET_ERROR_RESPONSE', error.response);
        return error.response;
      }
    },
    FETCH_DEAL_DATA: async (context) => {
      const { agencyAuthorized } = context.state.agencyUsers;
      try {
        const response = await get('CAMINGO', '/deal/inEffect/homepage', context);
        context.commit('SET_DEAL_DATA', response.data);
        return response;
      } catch (error) {
        if (agencyAuthorized && error.response.status === 401) context.commit('SET_AGENCY_LOGIN_STATE', true);
        context.commit('SET_ERROR_RESPONSE', error.response);
        return error.response;
      }
    },
    FETCH_MORE_HOTEL_INFO: async (context, payload) => {
      context.commit('SET_LOADING_STATE', true);
      const { hotelCode, supplierCode } = payload;
      const url = `/hotelInfo/${supplierCode}/${hotelCode}`;
      try {
        const response = await get('CAMINGO', url, context);
        context.commit('SET_ONE_HOTEL_INFO', response.data);
        context.commit('SET_LOADING_STATE', false);
        return response;
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        return error.response;
      }
    },
    FETCH_HOTELS_BY_OCCUPATION: async (context, payload) => {
      const url = '/operation/search';
      try {
        const response = await post('CAMINGO', url, payload, context);
        return response.data;
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        return error.response;
      }
    },
    SEND_BOOKING_DATA: async (context, payload) => {
      const { agencyAuthorized } = context.state.agencyUsers;
      window.localStorage.setItem('BookingDetailInfo', toBase64(JSON.stringify(payload)));
      context.commit('SET_BOOKING_INFO', payload);
      const dataID = payload.sendingStage === 'init' ? '' : context.state.bookingDataID || window.localStorage.getItem('BookingDataID') || '';
      const body = payload.data;
      const url = `/operation/storePage/${dataID}?langCode=${languageList[context.state.language]}`;

      try {
        const response = await post('CAMINGO', url, body, context);

        context.commit('SET_BOOKING_DATA_ID', response.data);
        return {
          success: true,
          id: response.data,
        };
      } catch (error) {
        if (agencyAuthorized && error.response.status === 401) context.commit('SET_AGENCY_LOGIN_STATE', true);
        context.commit('SET_ERROR_RESPONSE', error.response);
        context.dispatch('NOTIFY_ADMIN', {
          subject: `CAMINGO - StorePage is getting an error - ${error.response.status}`,
          body: `<h4>${JSON.stringify(body)}</h4> <p>${url}</p><p>${JSON.stringify(error.response)}</p>`,
        });
        return {
          success: false,
          error,
        };
      }
    },
    GET_PAYMENT_URL: async (context, payload) => {
      const { agencyAuthorized } = context.state.agencyUsers;
      context.commit('SET_PROCESSING_BOOKING_STATE', true);
      const url = `/operation/getPaymentUrl/${payload.bookID}?langCode=${languageList[context.state.language]}`;
      try {
        const response = await post('CAMINGO', url, payload.payerInfo, context);
        window.localStorage.setItem('agencyOwner', toBase64(JSON.stringify(response.data.agencyOwner)));
        context.commit('SET_PROCESSING_BOOKING_STATE', false);
        return {
          success: true,
          url: response.data,
        };
      } catch (error) {
        if (agencyAuthorized && error.response.status === 401) context.commit('SET_AGENCY_LOGIN_STATE', true);
        context.commit('SET_ERROR_RESPONSE', error.response);
        context.commit('SET_PROCESSING_BOOKING_STATE', false);
        context.dispatch('NOTIFY_ADMIN', {
          subject: `CAMINGO - GetPaymentUrl is getting an error - ${error.response.status}`,
          body: `<h4>${JSON.stringify(payload)}</h4> <p>${url}</p><p>${JSON.stringify(error.response)}</p>`,
        });
        return {
          success: false,
          error,
        };
      }
    },
    UPDATE_VERIFYING_URL: (context, payload) => {
      context.commit('SET_VERIFYING_URL', payload);
    },
    SEND_CLOSING_NOTICE: async (context, payload) => {
      let response = null;
      if (payload.withData) {
        const body = payload.data;
        const url = `/operation/bookingevent/${payload.bookDataID}/canceledWithBody?langCode=${languageList[context.state.language]}`;
        try {
          response = await postWithText('CAMINGO', url, body, context);
          return response;
        } catch (error) {
          context.commit('SET_ERROR_RESPONSE', error.response);
          return error.response;
        }
      } else {
        try {
          response = await post('CAMINGO', `/operation/bookingevent/${payload.bookDataID}/canceledWithoutBody?langCode=${languageList[context.state.language]}`, undefined, context);
          return response;
        } catch (error) {
          context.commit('SET_ERROR_RESPONSE', error.response);
          return error.response;
        }
      }
    },
    GET_BOOKED_INFO: async (context, payload) => {
      const { agencyAuthorized } = context.state.agencyUsers;
      context.commit('SET_PROCESSING_BOOKING_STATE', true);
      const BookingDataID = window.localStorage.getItem('BookingDataID');
      const url = `/operation/payAndBook/${BookingDataID}`;
      try {
        const response = await post('CAMINGO', url, undefined, context);
        context.commit('SET_PROCESSING_BOOKING_STATE', false);
        return response;
      } catch (error) {
        if (agencyAuthorized && error.response.status === 401) context.commit('SET_AGENCY_LOGIN_STATE', true);
        this.$store.dispatch('NOTIFY_ADMIN', {
          subject: `payAndBook is getting an error in Camingo - ${error.response.status}`,
          body: `<h4>${JSON.stringify(payload)}</h4> <p>${JSON.stringify(error.response)}</p>`,
        });
        context.commit('SET_ERROR_RESPONSE', error.response);
        context.commit('SET_PROCESSING_BOOKING_STATE', false);
        context.dispatch('NOTIFY_ADMIN', {
          subject: `CAMINGO - PayAndBook is getting an error - ${error.response.status}`,
          body: `<h4>${JSON.stringify(payload)}</h4> <p>${url}</p><p>${JSON.stringify(error.response)}</p>`,
        });
        return error;
      }
    },
    SET_GOOGLE_STATE: async (context) => {
      context.commit('SET_GOOGLE_STATE');
    },
    FETCH_BANNER_IMAGES: async (context, payload) => {
      const { page } = payload;
      const { agencyAuthorized } = context.state.agencyUsers;
      try {
        const response = await get('BFF', `/pageContentsAggregate/byPage/${page}?langCode=${languageList[context.state.language]}`, context);
        context.commit('SET_BANNER_IMAGES', response.data);
        return response.data;
      } catch (error) {
        if (agencyAuthorized && error.response.status === 401) context.commit('SET_AGENCY_LOGIN_STATE', true);
        context.commit('SET_ERROR_RESPONSE', error.response);
        return error.response;
      }
    },
    // FETCH_COUPON_INFO: async (context, payload) => {
    //   const { code, originalPrice, checkInDate, supplier, dealId } = payload;
    //   const paramDealId = dealId ? `&dealId=${dealId}` : '';
    //   let response = null;
    //   try {
    //     let url = `/operation/checkCoupon/${code}?langCode=${languageList[context.state.language]}&amount=${originalPrice}&checkinDate=${checkInDate}&supplier=${supplier}${paramDealId}`;
    //     const { subAgency } = context.state.agencyUsers;
    //     if (subAgency && subAgency !== '') url = `${url}&subAgency=${subAgency}`;
    //     const res = await get('CAMINGO', url, context);
    //     response = res.data;
    //     return response;
    //   } catch (error) {
    //     context.commit('SET_ERROR_RESPONSE', error.response);
    //     if (String(error).includes('400')) {
    //       response = {
    //         type: 'https://tools.ietf.org/html/rfc7231#section-6.5.1',
    //         title: 'One or more validation errors occurred.',
    //         status: 400,
    //         errors: {
    //           field: [
    //             'booking.invalid-coupon-price',
    //           ],
    //         },
    //       };
    //     } else {
    //       response = {
    //         type: 'https://tools.ietf.org/html/rfc7231#section-6.5.1',
    //         title: 'One or more validation errors occurred.',
    //         status: 500,
    //         errors: {
    //           field: [
    //             'booking.invalid-coupon-number',
    //           ],
    //         },
    //       };
    //     }
    //     return response;
    //   }
    // },
    FETCH_HOTEL_CHAIN: async (context) => {
      try {
        const response = await get('CAMINGO', '/hotelChain/flatten?from=1&to=1000', context); // all chain list
        context.commit('SET_HOTEL_CHAIN_TABLE', response.data.data);

        const responseID = await get('CAMINGO', '/deal/inEffectLp/homepage/chain', context); // chain list with deals
        context.commit('SET_HOTEL_ACTIVE_CHAIN', responseID.data);
        return response.data.data;
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        return error.response;
      }
    },
    FETCH_HOTEL_CATEGORY: async (context) => {
      try {
        const response = await get('CAMINGO', '/deal/inEffectLp/homepage/category', context); // category list with deals
        context.commit('SET_HOTEL_CATEGORY', response.data);
        return response.data.data;
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        return error.response;
      }
    },
    FETCH_ALL_HOTEL_INFO: async (context) => {
      context.commit('SET_LOADING_STATE', true);
      try {
        const response = await get('CAMINGO', '/hotelInfo?from=1&to=1000', context);
        context.commit('SET_HOTEL_ALL_INFO', response.data.data);
        context.commit('SET_LOADING_STATE', false);
        return response.data.data;
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        return error.response;
      }
    },
    FETCH_NATIONALITY_LIST: async (context) => {
      context.commit('SET_LOADING_STATE', true);
      try {
        const response = await get('CAMINGO', '/nationality?from=1&to=100', context);
        context.commit('SET_NATIONALITY', response.data.data);
        context.commit('SET_LOADING_STATE', false);
        return response.data.data;
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        return error.response;
      }
    },
    // Landing part
    FETCH_DEST_DATA_FOR_LP: async (context) => {
      try {
        const response = await get('CAMINGO', '/deal/inEffectLp/homepage/dest', context);
        context.commit('SET_DEST_DATA_FOR_LP', response.data);
        return response;
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        return error.response;
      }
    },
    STORE_IS_LANDING_STATE: (context, payload) => {
      context.commit('SET_LANDING_PAGE', VUE_APP_LANDING_PAGE_KEY in payload);
      context.commit('SET_LANDING_PAGE_CHAIN', VUE_APP_LANDING_PAGE_CHAIN_KEY in payload);
    },
    STORE_LANDING_URL: async (context, payload) => {
      const utmSource = payload[VUE_APP_LANDING_UTM_SOURCE_KEY] || 'general';
      // eslint-disable-next-line no-nested-ternary
      const phone = utmSource === 'google' ? VUE_APP_GOOGLE_PHONE_NUMBER : (utmSource === 'facebook' ? VUE_APP_FACEBOOK_PHONE_NUMBER : VUE_APP_DESKTOP_PHONE_NUMBER);
      const dealType = payload.dealType || '';

      const info = {
        destination: payload[VUE_APP_LANDING_PAGE_KEY],
        chainName: payload[VUE_APP_LANDING_PAGE_CHAIN_KEY],
        utm_source: utmSource,
        phone,
        dealType,
      };
      if (context.state.isLandingPage) {
        context.commit('SET_LANDING_INFO', info);
        try {
          const response = await get('CAMINGO', `/deal/inEffectLp/homepage/?destCode=${info.destination}`, context);
          context.commit('SET_LANDING_DEAL_DATA', response.data || []);
        } catch (error) {
          context.commit('SET_ERROR_RESPONSE', error.response);
        }
      } else if (context.state.isLandingPageChain) {
        context.commit('SET_LANDING_INFO', info);
        try {
          const response = await get('CAMINGO', `/deal/inEffectLp/homepage/?chainName=${info.chainName}`, context);
          context.commit('SET_LANDING_DEAL_CHAIN_DATA', response.data || []);
        } catch (error) {
          context.commit('SET_ERROR_RESPONSE', error.response);
        }
      }
    },
    SEND_CONTACT_FORM: async (context, payload) => {
      try {
        const response = await post('BFF', '/lead/leadInfo', payload, context);
        return response.data;
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        return error.response;
      }
    },
    NOTIFY_ADMIN_404_ERROR: async (context, payload) => {
      try {
        const res = await post('BFF', '/operation/notifyAdmin/pageNotFound', payload, context);
        return res.data.redirectPath;
      } catch (error) {
        context.commit('SET_ERROR_RESPONSE', error.response);
        return null;
      }
    },
    NOTIFY_ADMIN: async (context, payload) => {
      try {
        await post('BFF', '/operation/notifyAdmin', payload, context);
        return null;
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    // VIP reservation email part
    SEND_VIP_RESERVATION_EMAIL: async (context, payload) => {
      try {
        context.commit('SET_VIP_RESERVATION_EMAIL_RESULT', 'sending...');
        const response = await post('CAMINGO', '/operation/transferReservation', payload, context);
        context.commit('SET_VIP_RESERVATION_EMAIL_RESULT', response.data);
        return response.data;
      } catch (error) {
        console.log(error);
        return null;
      }
    },
  },
});
