<template>
  <div>
    <!-- <div v-if="loading">
      <content-loading type="product" />
    </div> -->
    <!-- <div v-else> -->
      <div v-if="variantClass !== 'success'">
        <b-card-group deck>
          <b-card
            :border-variant="variantClass"
            :header="header"
            :header-bg-variant="variantClass"
            :body-text-variant="variantClass"
            align="right"
          >
            <b-card-text>{{ message }}</b-card-text>
            <!-- <b-card-text><a :href="bookingInfoPageLink" target="_blank">{{$t('booking.error-link-message')}}</a></b-card-text> -->
          </b-card>
        </b-card-group>
        <button class="btn backDebit" @click="goToDebit" v-if="allowRetry">
          <span style="font-weight:600;">{{ $t('booking.try-again-debit') }}</span>
        </button>
      </div>
      <div v-else>
        <div class="container" v-if="device == 'desktop'">
          <h2 v-if="!isMEMSHAKAgent && bypassPaymentState">
            {{$t("booking.external-bypass-title")}}
            <p>{{ message }}</p>
            <p class="text-center" v-if="hotelOrderNumber">{{ $t('booking.hotel-confirmation-number')}}: {{ hotelOrderNumber }}</p>
          </h2>
          <h2 v-else-if="isMEMSHAKAgent || (!isMEMSHAKAgent && !bypassPaymentState)">
            {{$t("booking.fcl-confirm-title")}}
            <p>{{ message }}</p>
            <p class="text-center" v-if="hotelOrderNumber">{{ $t('booking.hotel-confirmation-number')}}: {{ hotelOrderNumber }}</p>
          </h2>
          <h2 v-else>
            {{ $t('booking.reservation') }}
            <strong >{{ $t('booking.confirmed') }}</strong>
            <p>{{ message }}</p>
            <p class="text-center" v-if="hotelOrderNumber">{{ $t('booking.hotel-confirmation-number')}}: {{ hotelOrderNumber }}</p>
          </h2>
        </div>
      </div>
      <!-- <div class="after_booking_area">
        <div class="order_no_box" style="background-image: url(/assets/img/after-booking-img1.jpg)">
          <div class="d-flex justify-content-start">
            <div class="order_no_div">
              <p>
                {{ $t('booking.for-order-tracking') }},
                {{ $t('booking.please') }}
                <a href="#">{{ $t('booking.click-here') }}</a
                ><br />
                {{ $t('booking.keep-order-name-desc') }}
              </p>
            </div>
            <div class="order_no_div">
              <h2>
                {{ $t('booking.your-order-no-is') + ' '
                }}<strong class="order_no">{{ orderNo }}</strong>
              </h2>
            </div>
          </div>
        </div>
      </div> -->

      <payer-confirm :payer="payer" />

      <hotel-confirm :bookedHotel="bookedHotel" />

      <div class="please_mall_area" v-if="showLink">
        <div class="row">
          <div class="col-lg-6">
            <div class="img_box">
              <!-- <img src="/assets/img/after-booking-img2.jpg" /> -->
              <img :src="bookedHotel.imageList[0] || '/assets/img/after-booking-img2.jpg'" />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="plase_mall_contain_box">
              <h3>
                <span>{{ $t('booking.please-note') }}:</span>
                {{ $t('booking.attached-mail') }}
              </h3>
              <div class="please_mall_contain_body">
                <div class="d-flex justify-content-between">
                  <!-- <div class="please_mall">
                    <div class="icon_box">
                      <b-link :href="pdfUrlItinerary" @click.prevent="downloadTickets(pdfUrlItinerary, 'Itinerary')">
                        <img src="/assets/img/after-booking-icon16.png"/>
                      </b-link>
                    </div>
                    <h5>{{ $t('booking.itinerary') }}</h5>
                  </div> -->
                  <div class="please_mall">
                    <div class="icon_box">
                      <b-link :href="pdfUrlHotelVoucher" @click.prevent="downloadTickets(pdfUrlHotelVoucher, 'HotelVoucher')">
                        <img src="/assets/img/after-booking-icon18.png"/>
                      </b-link>
                    </div>
                    <h5>{{ $t('booking.hotel-voucher') }}</h5>
                  </div>
                  <!-- <div class="please_mall">
                    <div class="icon_box">
                      <b-link :href="pdfUrlTicket" @click.prevent="downloadTickets(pdfUrlTicket, 'Ticket')">
                        <img src="/assets/img/after-booking-icon17.png"/>
                      </b-link>
                    </div>
                    <h5>{{ $t('booking.flight-tickets') }}</h5>
                  </div> -->
                  <div class="please_mall">
                    <div class="icon_box">
                      <b-link :href="pdfUrlReceipt" @click.prevent="downloadTickets(pdfUrlReceipt, 'Receipt')">
                        <img src="/assets/img/after-booking-icon15.png"/>
                      </b-link>
                    </div>
                    <h5>{{ $t('booking.receipt') }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <flight-confirm :flights="flights" /> -->
    <!-- </div> -->
  </div>
</template>

<script>
import { BCardGroup, BCard, BLink, BCardText } from 'bootstrap-vue';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import { fromBase64 } from '@/utils/base64Util';
import googleAnalytics from '@/mixins/googleAnalytics';

const { VUE_APP_ADMIN_DOMAIN, VUE_APP_ADMIN_DOMAIN_STAGING, VUE_APP_ADMIN_DOMAIN_AZURE } = process.env;

export default {
  components: {
    BCardGroup,
    BCard,
    BLink,
    BCardText,
    // ContentLoading: () => import('@/components/atoms/ContentLoading'),
    PayerConfirm: () => import('@/components/booking/atoms/PayerConfirm'),
    HotelConfirm: () => import('@/components/booking/atoms/HotelConfirm'),
  },
  mixins: [googleAnalytics],
  data() {
    return {
      // loading: false,
      bookingStage: 3,
      variantClass: 'success',
      variant: 'success',
      paymentSucceeded: 0,
      header: '',
      message: '',
      hotelOrderNumber: '',

      allowRetry: 'false',
      showLink: false,
      pdfUrlHotelVoucher: null,
      pdfUrlReceipt: null,

      orderNo: 'N/A',
      payer: null,
      payerJSON: '',
      price: 0,
      bookedHotel: null,
      // flights: {
      //   outward: {},
      //   inward: {},
      // },

      product: null,
      response: null,

      downDocument: null,

      showWaiting: false,
      waitingTime: 10 * 60 * 1000,

      cantDown: false,
      gtaBookingState: {
        success: 'בוצע בהצלחה',
        warning: 'לא בוצעה הזמנה',
        danger: 'שגיאה',
      },
    };
  },
  computed: {
    ...mapGetters({
      // isLoading: 'GET_LOADING_STATE',
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      whiteLabel: 'GET_WHITE_LABEL',
      marketerId: 'GET_MARKETER_ID',
      bypassPaymentState: 'GET_BYPASS_PAYMENT_STATE',
      isMEMSHAKAgent: 'IS_MEMSHAK_ODYSSEY_AGENT_CODE',
      isStaging: 'GET_IS_STAGING_STATE',
      isAzure: 'GET_IS_AZURE_STATE',
    }),
    bookingInfoPageLink() {
      // eslint-disable-next-line no-nested-ternary
      const host = this.isStaging ? VUE_APP_ADMIN_DOMAIN_STAGING : (this.isAzure ? VUE_APP_ADMIN_DOMAIN_AZURE : VUE_APP_ADMIN_DOMAIN);
      return `${host}/camingo/demobookingInfo/${this.bookingDataID}`;
    },
  },
  watch: {
    // isLoading() {
    //   this.loading = this.isLoading;
    // },
    lang: 'updateLabelWithLang',
  },
  async created() {
    this.$emit('setBookingStage', 3);
    window.scrollTo(0, 0);

    this.product = JSON.parse(fromBase64(window.localStorage.getItem('production')));
    const BookingDetailInfo = JSON.parse(fromBase64(window.localStorage.getItem('BookingDetailInfo')));
    this.payer = {
      orderNo: '',
      status: '',
      name: '',
      mobile: '',
      email: '',
      price: '',
    };
    this.payer = (this.bypassPaymentState) ? BookingDetailInfo.data.paxList[0] : BookingDetailInfo.data.payer;
    this.payer.orderNo = this.orderNo;
    this.payer.status = 'ERROR';
    this.payer.price = BookingDetailInfo.data.grandTotalAfterDiscount;

    this.bookedHotel = {
      hotelPrice: 0,
      hotelName: this.product.hotel.hotelName,
      period: `${dayjs(this.product.hotel.checkinDate).format('DD/MM/YYYY')}-${dayjs(this.product.hotel.checkoutDate).format('DD/MM/YYYY')}`,
      supplements: this.product.hotel.hotelSupplierCode,
      rooms: [],
      imageList: [],
    };
    const { rooms } = BookingDetailInfo.data.hotel;
    this.bookedHotel.rooms = rooms;
    this.bookedHotel.price = BookingDetailInfo.data.grandTotalAfterDiscount;
    this.bookedHotel.hotelPrice = BookingDetailInfo.data.grandTotalAfterDiscount;

    this.bookedHotel.imageList = this.product.moreHotelInfo.allImageUrls;

    await this.makeOrder();

    // window.addEventListener('beforeunload', this.ClosingWindow);
  },
  mounted() {
    // this.$bvToast.show('waiting');
    // const gtagID = 'UA-66183279-1';
    // const gtagScript = document.createElement('script');
    // gtagScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${gtagID}`);
    // document.head.appendChild(gtagScript);
    // window.dataLayer = window.dataLayer || [];

    // this.gtag('js', new Date());
    // this.gtag('config', gtagID);
  },
  destroyed() {
    // window.removeEventListener('beforeunload', this.ClosingWindow);
  },
  methods: {
    async makeOrder() {
      this.cantDown = false;
      // const { ConfirmationKey } = this.$route.query;
      const BookingDetailInfo = JSON.parse(fromBase64(window.localStorage.getItem('BookingDetailInfo')));
      // const marketerURL = JSON.parse(window.localStorage.getItem('marketerURL'));

      let res = null;
      if (this.bypassPaymentState) {
        res = JSON.parse(fromBase64(window.localStorage.getItem('getPaymentResponse')));
      } else {
        // res = await this.$store.dispatch('GET_BOOKED_INFO', { data: BookingDetailInfo.data, ConfirmationKey });
        res = await this.$store.dispatch('GET_BOOKED_INFO', { data: BookingDetailInfo.data });
        if (res.data && res.data.error && Number(res.data.error.code) === 1) {
          res = await this.$store.dispatch('GET_SUCCEED_BOOKING');
          this.cantDown = true;
        }
      }

      this.response = res;
      this.updateLabelWithLang();
      if (this.showLink && !this.cantDown) {
        if (this.pdfUrlHotelVoucher) this.downloadTickets(this.pdfUrlHotelVoucher, 'HotelVoucher');
        if (this.pdfUrlReceipt) this.downloadTickets(this.pdfUrlReceipt, 'Receipt');
      }

      // if (!window.location.host.includes('vercel')) {
      //   const affiliation = fromBase64(window.localStorage.getItem('agencyOwner')),
      //     price = BookingDetailInfo.data.priceBeforeCoupon;
      //   this.gtag('event', 'purchase', {
      //     transaction_id: this.response?.data?.pnr || null, // RANDON NUMBER
      //     affiliation,
      //     value: price,
      //     currency: 'ILS',
      //     tax: 0,
      //     shipping: 0,
      //     items: [
      //       {
      //         id: this.response?.data?.pnr || null, // PNR
      //         name: `${this.product.hotel.hotelName} ${this.product.hotel.cityName}`, /// DEAL TYPE
      //         list_name: `${this.product.hotel.checkinDate} - ${this.product.hotel.checkoutDate}`, // DESTINATION
      //         brand: this.bookedHotel.hotelName, // HOTEL NAME
      //         category: this.gtaBookingState[this.variantClass], // BOOKING STATE
      //         variant: this.allowRetry, // Retrying state of the booking
      //         list_position: 1,
      //         quantity: 1,
      //         price, // TOTAL SUM
      //       },
      //     ],
      //   });
      // }
    },
    showWaitingToast(pType, message) {
      if (pType === 'show') {
        const h = this.$createElement;
        const vNodesMsg = h('div', { class: ['dp-flex'] }, [
          h('span', {}, message || this.$t('booking.waiting-populating')),
          h('b-img', { props: { src: '/assets/img/waiting.gif' } }),
        ]);
        const vNodesTitle = h(
          'div',
          { class: ['d-flex', 'flex-grow-1', 'align-items-baseline'] },
          [h('strong', {}, this.$t('booking.waiting'))],
        );
        this.$bvToast.toast([vNodesMsg], {
          title: [vNodesTitle],
          solid: true,
          noAutoHide: true,
        });
      } else if (pType === 'hide') {
        this.$bvToast.hide();
      }
    },
    updateLabelWithLang() {
      // if need translate for flights.confirmed, please add it here
      if (this.response && this.response.status === 200) {
        const stateBook = this.response.data;
        const { error } = stateBook;

        this.showLink = stateBook.paymentSucceeded;
        if (!error) {
          this.variant = stateBook.paymentSucceeded ? this.$t('booking.success') : this.$t('booking.warning');
          this.variantClass = stateBook.paymentSucceeded ? 'success' : 'warning';
          this.header = this.variant.toUpperCase();
          this.message = stateBook.messageToVisitor;// stateBook.status.description;
          this.hotelOrderNumber = stateBook.hotelOrderNumber;
          this.orderNo = stateBook.pnr;
          this.payer.status = stateBook.paymentSucceeded ? 'OK' : 'RQ';
          this.payer.orderNo = this.orderNo;
          this.payer.price = stateBook.totalPayment;
          if (this.bookedHotel) this.bookedHotel.hotelPrice = stateBook.totalPayment;

          this.allowRetry = false;
          window.localStorage.setItem('retryState', null);
          if (this.showLink) {
            // this.pdfUrlItinerary = stateBook.pdfUrlItinerary;
            this.pdfUrlHotelVoucher = stateBook.pdfUrlHotelVoucher;
            // this.pdfUrlTicket = stateBook.pdfUrlTicket;
            this.pdfUrlReceipt = stateBook.pdfUrlReceipt;
          }
        } else {
          this.variant = this.$t('booking.danger');
          this.variantClass = 'danger';
          this.header = this.$t('booking.error');
          this.message = error.message;
          this.orderNo = stateBook.pnr || 'N/A';
          this.payer.orderNo = this.orderNo;

          const retryState = {
            // readOnly: error.retryPaymentMethodOnly,
            readOnly: !!stateBook.pnr,
          };
          window.localStorage.setItem('retryState', JSON.stringify(retryState));
          if (error.retryAllowed) {
            this.allowRetry = true;
          } else {
            this.allowRetry = false;
          }
        }
        this.payer = JSON.parse(JSON.stringify(this.payer));
      } else if (this.bypassPaymentState && this.response.success) {
        const stateBook = this.response.url;
        const { error } = stateBook;

        this.showLink = stateBook.paymentSucceeded;
        if (!error) {
          this.variant = stateBook.paymentSucceeded ? this.$t('booking.success') : this.$t('booking.warning');
          this.variantClass = stateBook.paymentSucceeded ? 'success' : 'warning';
          this.header = this.variant.toUpperCase();
          this.message = stateBook.messageToVisitor;
          this.hotelOrderNumber = stateBook.hotelOrderNumber;
          this.orderNo = stateBook.pnr;
          this.payer.status = stateBook.paymentSucceeded ? 'OK' : 'RQ';
          this.payer.orderNo = this.orderNo;
          this.payer.price = stateBook.totalPayment;
          if (this.bookedHotel) this.bookedHotel.hotelPrice = stateBook.totalPayment;

          this.allowRetry = false;
          window.localStorage.setItem('retryState', null);
          if (this.showLink) {
            // this.pdfUrlItinerary = stateBook.pdfUrlItinerary;
            this.pdfUrlHotelVoucher = stateBook.pdfUrlHotelVoucher;
            // this.pdfUrlTicket = stateBook.pdfUrlTicket;
            this.pdfUrlReceipt = stateBook.pdfUrlReceipt;
          }
        } else {
          this.variant = this.$t('booking.danger');
          this.variantClass = 'danger';
          this.header = this.$t('booking.error');
          this.message = error.message;
          this.orderNo = stateBook.pnr || 'N/A';
          this.payer.orderNo = this.orderNo;

          const retryState = {
            // readOnly: error.retryPaymentMethodOnly,
            readOnly: !!stateBook.pnr,
          };
          window.localStorage.setItem('retryState', JSON.stringify(retryState));
          if (error.retryAllowed) {
            this.allowRetry = true;
          } else {
            this.allowRetry = false;
          }
        }
        this.payer = JSON.parse(JSON.stringify(this.payer));
      } else {
        this.header = this.$t('booking.error');
        this.variant = this.$t('booking.danger');
        this.message = this.$t('booking.error');
        this.variantClass = 'danger';
        this.allowRetry = false;
      }
    },
    ClosingWindow() {
      const bookingDataID = this.bookingDataID || window.localStorage.getItem('BookingDataID');
      this.$store.dispatch('SEND_CLOSING_NOTICE', { bookDataID: bookingDataID, withData: false });
    },
    getFullDate(pDate, pTime) {
      const date = dayjs(`${pDate} ${pTime}`).format('DD/MM/YYYY, hh:mm a');
      return date;
    },
    downloadTickets(pURL, pName) {
      this.$bvToast.toast(this.$t('booking.waiting-downloading'), {
        title: this.$t('booking.downloading'),
        autoHideDelay: 30 * 1000,
        appendToast: true,
        variant: 'info',
        noCloseButton: true,
        bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
      });
      // const link = document.createElement('a');
      // link.setAttribute('href', pURL);
      // link.setAttribute('download', `${pName}.pdf`);
      // link.style.display = 'none';
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      const fileName = `${pName}.pdf`;
      if (!window.ActiveXObject) {
        const save = document.createElement('a');
        save.href = pURL;
        save.target = '_blank';
        save.download = fileName;
        if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search('Chrome') < 0) {
          document.location = save.href;
          // window event not working here
        } else {
          const evt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: false,
          });
          save.dispatchEvent(evt);
          (window.URL || window.webkitURL).revokeObjectURL(save.href);
        }
      } else if (!!window.ActiveXObject && document.execCommand) {
        const _window = window.open(pURL, '_blank');
        _window.document.close();
        _window.document.execCommand('SaveAs', true, fileName);
        _window.close();
      }
    },
    goToDebit() {
      // window.removeEventListener('beforeunload', this.ClosingWindow);
      this.$router.push({ path: '/booking/hotel' });
    },
  },
};
</script>

<style>
#waiting.ltr {
  direction: ltr;
}
#waiting.rtl {
  direction: rtl;
}
#waiting h5 {
  padding: 15px 5px;
  font-size: 15px;
}
#waiting img {
  width: 100px;
}
#waiting h3 {
  font-size: 22px;
  font-weight: 600;
  color: #313131;
  text-align: center;
}
</style>

<style scoped>
.card-deck .card {
  margin-bottom: 25px;
}
button.backDebit {
  background: #0061ab;
  color: #fff;
  margin: 10px 10px;
}

h1 {
  font-size: 45px;
  font-weight: 400;
  color: #0e436b;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.after_booking_area .order_no_box {
  width: 100%;
  height: 120px;
  box-sizing: border-box;
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 20px;
  margin-bottom: 25px;
}
.after_booking_area .order_no_box .order_no_div {
  width: 100%;
  text-align: left;
}
.after_booking_area .order_no_box .order_no_div h2 {
  margin: 0 0;
  padding: 6px 0;
  color: #fff;
  font-size: 26px;
  font-weight: 400;
  text-transform: uppercase;
}
.after_booking_area .order_no_box .order_no_div h2 strong.order_no {
  color: #79bff5;
}
.after_booking_area .order_no_box .order_no_div p {
  margin: 0 0;
  color: #fff;
  font-size: 15px;
}
.after_booking_area .order_no_box .order_no_div p a {
  text-decoration: underline;
  color: #fff;
  transition: 0.5s;
}
.after_booking_area .order_no_box .order_no_div p a:hover {
  text-decoration: none;
}

.please_mall_area {
  margin-bottom: 25px;
}
.please_mall_area .plase_mall_contain_box {
  border: 1px solid #949494;
}
.please_mall_area .plase_mall_contain_box h3 {
  padding: 20px 20px;
  border-bottom: 1px solid #949494;
  font-size: 24px;
  text-transform: uppercase;
}
.please_mall_area .plase_mall_contain_box h3 span {
  color: #0763ab;
}
.please_mall_area .please_mall_contain_body {
  padding: 30px 20px;
}
.please_mall_contain_body .please_mall {
  width: 100%;
  text-align: center;
}
.please_mall_contain_body .please_mall .icon_box {
  width: 88px;
  height: 82px;
  border: 1px solid #0763ab;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto 15px;
}
.please_mall_contain_body .please_mall h5 {
  text-transform: uppercase;
  color: #000;
  margin: 0 0;
  font-size: 16px;
}
.please_mall_area .img_box {
  height: 275px;
  overflow: hidden;
  border-radius: 5px;
}

@media (min-width: 991.98px) and (max-width: 1200px) {
  .please_mall_contain_body .please_mall .icon_box {
    width: 70px;
    height: 70px;
  }
}

@media (max-width: 1200px) {
  .please_mall_area .img_box img {
    width: 100%;
  }
}

@media (max-width: 479px) {
  .after_booking_area .order_no_box {
    width: 100%;
    height: auto;
    background-position: right;
    padding: 5px 20px;
    margin-bottom: 15px;
  }
  .order_no_box .d-flex {
    display: block !important;
  }
  .after_booking_area .order_no_box .order_no_div p {
    font-size: 12px;
  }
  .after_booking_area .order_no_box .order_no_div h2 {
    font-size: 16px;
  }
  .please_mall_area .img_box {
    height: auto;
  }
  .please_mall_area .plase_mall_contain_box h3 {
    padding: 14px 13px;
    font-size: 15px;
    text-transform: uppercase;
    text-align: center;
  }
  .please_mall_area .please_mall_contain_body {
    padding: 0px 10px 15px;
  }
  .please_mall_area .please_mall_contain_body .d-flex {
    display: block !important;
  }
  .please_mall_area .please_mall_contain_body .please_mall {
    margin-top: 15px;
  }
  .please_mall_area .please_mall_contain_body .please_mall {
    width: 100%;
    text-align: center;
  }
}
</style>
